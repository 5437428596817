import U3Track from './U3Track.vue';
  import draggable from 'vuedraggable'

  export default {

    name: "u3-queue",
    props: [],

    components: {
      'u3-track': U3Track,
      draggable
    },

    data(){
      return {
        queue: [],                // contains plain objects describing videos
      };
    }, // data

    computed:{
      playingTrackIndex(){
        return this.getTrackIndex( this.$store.state.currentTrack );
      }
    }, // computed

    methods: {


      /**
       * Makes a track out of a video, and adds it to the queue
       * @param {Object} video - Object describing the video
       */
      queueVideo( video ){

        /*
        // parse video Youtube id from url
        let id = video.url.split('v=')[1];
        let ampersandPosition = id.indexOf('&');
        if (ampersandPosition != -1) id = id.substring(0, ampersandPosition);
        video.id = id;
        */

        let track = {
          video: video,
          id: video.id + '-' + Math.random()
        };

        // queue video
        this.queue.push( track );

        // if video is first of queue, play it
        if ( this.queue.length == 1 ) u3.events.$emit('play-track', this.queue[0] );
      },


      /** adds a video to the queue
      queueTrackFromInput(){
        this.queueTrack( this.new_video_reference );
        this.new_video_reference = null;
      },
      */

      /**
       * Starts playing a video track
       * @param  {Object} track - describes the track props
       * @return {void}
       */
      playTrack( track ){

        // if a player allready exists, destroy it
        if ( u3.player ) u3.player.destroy();

        // create a player for given video
        u3.player = new YT.Player('player', {
          height:  track.video.width  || '360',
          width:   track.video.height || '640',
          videoId: track.video.id,
          events: {
            onReady(event){ event.target.playVideo() },
            onStateChange:function(event){
              /*
                -1 : non démarré
                0 : YT.PlayerState.ENDED (arrêté)
                1 : YT.PlayerState.PLAYING (en lecture)
                2 : YT.PlayerState.PAUSED (en pause)
                3 : YT.PlayerState.BUFFERING (en mémoire tampon)
                5 : YT.PlayerState.CUED (en file d'attente)
              */
              if (event.data == YT.PlayerState.ENDED ){
                this.playNextTrack();
              }
            }.bind(this)
          }
        });

        this.$store.commit('setCurrentTrack', track );
      },

      /**
       * Returns the index of givent ${track} into queue
       * @param  {Object} track
       * @return {Integer}
       */
      getTrackIndex(track){
        return this.queue.map(function( track ) { return track.id; }).indexOf( track.id );
      },

      /** Starts playing the next track in queue (if any) */
      playNextTrack(){
        let indexOfCurrentTrack = this.getTrackIndex( this.$store.state.currentTrack );

        if ( indexOfCurrentTrack+1 < this.queue.length ){
          u3.events.$emit('play-track', this.queue[indexOfCurrentTrack+1] );
        }
      },

      /**
       * Removes given ${track} from queue
       * @param  {Object} track
       * @return {void}
       */
      unqueueTrack(track){
        let trackIndex = this.getTrackIndex(track);
        this.queue.splice( trackIndex, 1 );
      },

      flush(){
        if ( this.playingTrackIndex > 0 ) this.flushPassed();
        else if ( this.playingTrackIndex == 0 ) {
          if ( this.queue.length > 1 ) this.flushFollowing();
          else this.flushAll();
        }
      },

      flushPassed(){
        this.queue.splice( 0, this.playingTrackIndex );
      },

      flushFollowing(){
        this.queue.splice( this.playingTrackIndex+1, this.queue.length - this.playingTrackIndex );
      },

      flushAll(){
        if ( u3.player ) u3.player.destroy();
        this.queue = [];
      },

       collapse(){
         u3.events.$emit('collapse-all-suggestions');
       }
    }, // methods

    mounted(){
      u3.events.$on('play-track', this.playTrack);
      u3.events.$on('queue-video', this.queueVideo);
      u3.events.$on('unqueue-track', this.unqueueTrack);
    } // mounted

  }

<template>
  <div>

    <!-- close button -->
    <a v-if="close" @click.prevent="onCloseButtonClicked" class="bton --small float-right" href="#">&times;</a>

    <!-- related videos button -->
    <a
      @click.prevent.stop="showRelatedVideos=!showRelatedVideos"
      href="#"
      class="badge badge-light border float-right"
      v-html="showRelatedVideos ? '&minus;' : '&plus;'"
    ></a>

    <!-- video data and link -->
    <a class="d-block" style="overflow: hidden" @click.prevent="onVideoClicked" href="#">
      <img :src="thumbnails" class="float-left mr-2 img-thumbnail" style="width: 60px;">
      <div>
        <div>{{ title }}</div>
        <slot></slot>
      </div>
    </a>

    <!-- related videos list -->
    <div v-if="showRelatedVideos" class="alert alert-info mt-2">
      <u3-search :related-to-video-id="id" :max-results="5" />
    </div>

  </div>
</template>

<style media="screen">
  .bton {
    border: 1px solid #ccc;
    padding: .25em .5em;
    margin: 0;
    background: #fff;
    color: #333;
    border-radius: .25em;
  }
  .bton:hover {
    text-decoration: none;
    background-color: #f0f0f0;
  }
  .--small {
    font-size: .75rem;
  }
</style>

<script>
  // import U3Search from "./U3Search.vue";
  export default {

    name: "u3-video",
    props: [
      "title", "thumbnails", "id", "url",
      "close"
    ],

    components: {
      // "u3-search": U3Search
    },

    data(){
      return {
        showRelatedVideos: false,
      	suggested: []
      };
    }, // data

    computed:{}, // computed

    methods: {
      onVideoClicked(){
        if ( this.$parent.onVideoClicked ) {
          this.$parent.onVideoClicked();
        }
        else return false;
      },

      onCloseButtonClicked(){
        if ( this.$parent.onCloseButtonClicked ) {
          this.$parent.onCloseButtonClicked();
        }
        else return false;
      },

      collapseRelativeVideos(){
        this.showRelatedVideos=false
      }
    }, // methods

    mounted(){
      u3.events.$on('collapse-all-suggestions', this.collapseRelativeVideos);
    }

  }
</script>

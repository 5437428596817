var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "z-index": "10000" }, attrs: { id: "u3-template" } },
    [
      _vm.showU3
        ? _c("main", { staticClass: "ui m-0 p-0 d-flex flex-column" }, [
            _c("div", { staticClass: "pt-3" }, [
              _vm._m(0),
              _vm._v(" "),
              _vm.debug
                ? _c("pre", { staticClass: "alert alert-info" }, [
                    _vm._v("      " + _vm._s(_vm.state.currentTrack) + "\n    ")
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex-fill d-flex",
                staticStyle: { overflow: "hidden" }
              },
              [
                _c(
                  "div",
                  { staticClass: "search col-6 h-100" },
                  [_c("u3-search")],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "playlist col-6 d-flex flex-column" },
                  [
                    _c("div", {
                      staticClass: "w-100 mb-3",
                      staticStyle: { "min-height": "300px" },
                      attrs: { id: "player" }
                    }),
                    _vm._v(" "),
                    _c("u3-queue")
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("nav", { staticClass: "display-button m-2" }, [
        _c("button", {
          staticClass: "btn btn-success",
          attrs: { href: "#" },
          domProps: {
            innerHTML: _vm._s((_vm.showU3 ? "Hide" : "Show") + " U3")
          },
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              _vm.showU3 = !_vm.showU3
            }
          }
        })
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [_vm._v("U"), _c("sup", [_vm._v("3")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }
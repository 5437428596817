var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.close
      ? _c(
          "a",
          {
            staticClass: "bton --small float-right",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.onCloseButtonClicked($event)
              }
            }
          },
          [_vm._v("×")]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("a", {
      staticClass: "badge badge-light border float-right",
      attrs: { href: "#" },
      domProps: {
        innerHTML: _vm._s(_vm.showRelatedVideos ? "&minus;" : "&plus;")
      },
      on: {
        click: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          _vm.showRelatedVideos = !_vm.showRelatedVideos
        }
      }
    }),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "d-block",
        staticStyle: { overflow: "hidden" },
        attrs: { href: "#" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.onVideoClicked($event)
          }
        }
      },
      [
        _c("img", {
          staticClass: "float-left mr-2 img-thumbnail",
          staticStyle: { width: "60px" },
          attrs: { src: _vm.thumbnails }
        }),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("div", [_vm._v(_vm._s(_vm.title))]),
            _vm._v(" "),
            _vm._t("default")
          ],
          2
        )
      ]
    ),
    _vm._v(" "),
    _vm.showRelatedVideos
      ? _c(
          "div",
          { staticClass: "alert alert-info mt-2" },
          [
            _c("u3-search", {
              attrs: { "related-to-video-id": _vm.id, "max-results": 5 }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-100 d-flex flex-column" }, [
    !_vm.relatedToVideoId
      ? _c(
          "form",
          {
            staticClass: "pb-3",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.search($event)
              }
            }
          },
          [
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchTerms,
                    expression: "searchTerms"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  debounce: "200",
                  placeholder: "search videos"
                },
                domProps: { value: _vm.searchTerms },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchTerms = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _vm._m(0)
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.results.length
      ? _c(
          "ul",
          {
            staticClass: "list-group",
            staticStyle: { "overflow-y": "scroll" }
          },
          _vm._l(_vm.results, function(item) {
            return _c(
              "li",
              { staticClass: "list-group-item" },
              [_c("u3-search-result", { attrs: { result: item } })],
              1
            )
          })
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.searchTerms || _vm.relatedToVideoId
      ? _c("div", { directives: [{ name: "else", rawName: "v-else" }] }, [
          _vm._v("\n    Loading...\n  ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c(
        "button",
        { staticClass: "btn btn-outline-success", attrs: { type: "submit" } },
        [_vm._v("Search")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
import U3Video from "./U3Video.vue";

export default {

  name: "u3-track",
  props: ["video", "id"],

  components: {
    "u3-video": U3Video
  },

  data(){
    return {
    };
  }, // data

  computed:{

    hash(){
      return {
        id: this.id,
        video: this.video
      }
    }
  }, // computed

  methods: {
    playing(){
      return u3.store.getters.isCurrentVideo( this.video );
    },

    /** Starts playing this video */
  	onVideoClicked(){
      console.info(JSON.stringify(this.hash));
  		u3.events.$emit('play-track', this.hash);
  	},


    /** Removes this video form queue */
    onCloseButtonClicked(){
      u3.events.$emit('unqueue-track', this.hash);
    }
  }, // methods

  created(){
  }, // created


  mounted(){
    /*
    u3.events.$on('play-track', function(track){
      this.playing = (track.id == this.track.id);
    }.bind(this));
    */
  } // mounted

}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { active: _vm.playing() } },
    [
      _c(
        "span",
        {
          staticClass: "handle float-right",
          on: {
            click: function($event) {
              $event.preventDefault()
              $event.stopPropagation()
            }
          }
        },
        [_vm._v("⇕")]
      ),
      _vm._v(" "),
      _c(
        "u3-video",
        _vm._b({ attrs: { close: true } }, "u3-video", _vm.video, false),
        [
          _vm.playing()
            ? _c("span", { staticClass: "badge badge-warning" }, [
                _vm._v("playing")
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from "vue";
import store from './store';

import U3Search from "../vue/U3Search.vue";
Vue.component('u3-search', U3Search);

import App from '../vue/App.vue';

(function(window, document, undefined){

	// initialise an app container
	window.u3 = {};

	// Initialize an events handler and shared storage
	u3.events = new Vue();
	u3.store = store;

	// Configure YouTube Player
	window.onYouTubeIframeAPIReady = function() { // (after the API code downloads) Create <iframe> and YouTube player
		// console.info("onYouTubeIframeAPIReady");
	};

	// create the Vue element
	let vueEl = document.createElement('div'),
		body = document.body,
		firstElem = body.firstElementChild;
	if ( firstElem ) body.insertBefore(vueEl, firstElem);
	else body.appendChild(vueEl);
	vueEl.id = 'u3';
	vueEl.innerHTML = "u3";


	// Initialize Vue app
	new Vue({
	  el: '#u3',
	  template: '<App/>',
	  store,
	  components: { App }
	});
	

})(window, document);
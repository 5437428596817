import Vue from "vue";
import Vuex from 'vuex';
Vue.use(Vuex);

export default new Vuex.Store({
  	state: {
	  	debug:  window.debug,
	    videos: [],
	    currentTrack: null,
  	}, // state

  	getters: {
  		// returns a video from cache
	  	getVideoById: (state) => (id) => {
	    	return state.videos.find(vid => vid.id === id)
	  	},

	  	/**
	  	 * Returns either given video is in the current track
	  	 * @param  {Object}  video
	  	 * @return {Boolean}
	  	 */
	  	isCurrentVideo: (state) => (video) => {
	  		return state.currentTrack.video.id == video.id;
	  	}
	}, // getters

  	mutations: {
	    setCurrentTrack( state, track ) {
	      state.currentTrack = track;
	    }
  	} // mutations
});

import U3Video from "./U3Video.vue";

export default {

  name: "u3-search-result",
  props: ["result"],

  components: {
    'u3-video': U3Video
  },

  data(){
    return {
    	
    };
  }, // data

  computed:{}, // computed

  methods: {
    /**
     * Adds this video to the queue
     * @return {void}
     */
  	onVideoClicked(){
      u3.events.$emit('queue-video', this.result);
  	}
  } // methods

}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.queue.length
    ? _c(
        "div",
        {
          staticClass: "d-flex flex-column",
          staticStyle: { "overflow-y": "hidden" }
        },
        [
          _c("div", [
            _c(
              "button",
              {
                staticClass: "btn btn-warning badge-pill border",
                attrs: { type: "button" },
                on: { click: _vm.flush }
              },
              [_vm._v("flush")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary badge-pill border",
                attrs: { type: "button" },
                on: { click: _vm.collapse }
              },
              [_vm._v("collapse")]
            )
          ]),
          _vm._v(" "),
          _c(
            "draggable",
            {
              staticClass: "list-group pb-3 d-flex flex-column",
              staticStyle: { "overflow-y": "scroll" },
              attrs: { options: { handle: ".handle" } },
              model: {
                value: _vm.queue,
                callback: function($$v) {
                  _vm.queue = $$v
                },
                expression: "queue"
              }
            },
            _vm._l(_vm.queue, function(video, index) {
              return _c(
                "div",
                {
                  key: video.id,
                  staticClass: "list-group-item",
                  attrs: { video: video, index: index }
                },
                [_c("u3-track", _vm._b({}, "u3-track", video, false))],
                1
              )
            })
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import U3Queue from './U3Queue.vue';
// import U3Search from './U3Search.vue';
export default {

  name: "app",
  props: [],

  components: {
  	'u3-queue':  U3Queue,
  	// 'u3-search': U3Search,
  },

  data(){
    return {
      showU3: true,
    };
  }, // data

  computed:{
    debug(){
      return u3.store.state.debug;
    },
    state(){
      return this.$store.state;
    }
  }, // computed

  methods: {



    ytApp(){
      return document.querySelector('ytd-app');
    },

    showYtApp(){
      let ytApp = this.ytApp();
      if (ytApp) ytApp.style.display = "block";
    },

    hideYtApp(){
      let ytApp = this.ytApp();
      if (ytApp) ytApp.style.display = "none";
    },

    toggleYtApp(){
      let ytApp = this.ytApp();
      if (ytApp) ytApp.style.display = (ytApp.style.display=="none") ? "block" : "none";
    }
  }, // methods

  mounted() {

  	// Loads Youtube IFrame Player API code asynchronously.
    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // hide youtube normal player
    this.hideYtApp();

    // for dev, automatically queue some videos
    if (0) window.onYouTubeIframeAPIReady = function(){
      u3.events.$emit('queue-video', "https://www.youtube.com/watch?v=T7YJoGXs2i8"); // Manset
      let i = 9;
      while ( i-- ) u3.events.$emit('queue-video', "https://www.youtube.com/watch?v=e5IBG8zuBt4"); // Desireless
    }.bind(this);

  }, // mounted

  watch: {
    showU3(){
      let ytApp = this.ytApp();
      if (ytApp) ytApp.style.display = this.showU3 ? "none" : "block";
    }, 
  }

}
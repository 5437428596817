import U3SearchResult from './U3SearchResult.vue';
import $ from 'jquery';
export default {

  name: "u3-search",
  props: {
    relatedToVideoId: {
      default: null
    },
    maxResults: {
      default: 15
    }
  },
  components: {
    "u3-search-result": U3SearchResult
  },

  data(){
    return {
    	results: [
        // "https://www.youtube.com/watch?v=IqxyOcqVIRU", // Himat
        // "https://www.youtube.com/watch?v=b4F-4s_-Org", // Farao
      ],
      searchTerms: u3.store.state.debug ? "Queen" : null
    };
  }, // data

  computed:{}, // computed

  methods: {
    search(){

      let data = {
        key:  "AIzaSyBaAVhmq6tJzxeZjfsQPlunymQMt6sTaTA",
        part: "snippet", // https://developers.google.com/youtube/v3/docs/videos/list
        maxResults: this.maxResults,
        type: "video"
      };
      if ( this.relatedToVideoId ) data.relatedToVideoId = this.relatedToVideoId;
      else data.q = this.searchTerms;

      $.get({url:"https://www.googleapis.com/youtube/v3/search", data:data}).done((resp)=>{
        if (resp.error) console.error(resp.error);
        else {
          // list results, and cache videos data
          this.results = [];
          resp.items.forEach(item=>{
            
            // cache video data, if no done already
            let video = u3.store.getters.getVideoById(item.id.videoId);
            if ( !video ) {
              // prepare video data
              video = {
                title: item.snippet.title,
                thumbnails: item.snippet.thumbnails.high.url,
                id: item.id.videoId,
              };
              video.url = "https://www.youtube.com/watch?v=" + video.id;
            }
            
            // add video to results
            this.results.push(video);
          });
        }
      });
      

    }
  }, // methods

  watch: {
    searchTerms(){
      return this.search();
    }
  },

  mounted(){
    if ( this.relatedToVideoId || this.searchTerms ){
      this.search();
    }
  }

}